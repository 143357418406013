* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#bui-3{
  z-index: 9;
}
body > div.gs.fq.fs.fu.ae.gt.gu.gv.gw.fi.ag.gx.cs{
  z-index: 9;
}

.hs {
  border-bottom-color: red !important;
}

.hr {
  border-top-color: red !important;
}

.hq {
border-right-color: red !important;
}

.hp {
border-left-color: red !important;
}

div[data-baseweb="popover"]{
  z-index:  99999999999;
}